<template>
  <div
    class="flex flex-col items-center justify-center h-screen bg-qgo-primary-bg"
  >
    <div class="flex flex-col items-center justify-center w-2/3 h-screen mt-5">
      <div class="flex flex-row items-center justify-center w-full pt-5">
        <div class="border-r-2">
          <img
            src="@/assets/img/Q&GO_Vert_GreyWhite.png"
            alt=""
            class="object-scale-down w-52"
          />
        </div>
        <div class="px-2 text-3xl text-white">Vendor</div>
      </div>
      <form autocomplete="off" @submit.prevent="reset">
        <div class="pt-6 mx-4 w-72">
          <div class="flex flex-col justify-center w-full">
            <input
              name="username"
              type="email"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b
                text-white
                focus:ring-0 focus:border-qgo-secondary-bg
                bg-qgo-primary-bg
              "
              placeholder="name@example.com"
              required
            />
            <p class="pt-2 text-red-600">{{ errorMsg }}</p>
          </div>
        </div>
        <div class="justify-center pt-6 mx-4 w-72">
          <button
            id="loginButton"
            type="submit"
            class="
              w-full
              p-2
              my-1
              mt-5
              text-black
              bg-white
              rounded-md
              focus:outline-none
            "
          >
            Reset Password
          </button>
          <button
            id="signupButton"
            class="
              w-full
              p-2
              my-1
              text-white
              rounded-md
              bg-qgo-secondary-bg
              focus:outline-none
            "
            @click="$router.push({ name: 'VendorSignin' })"
          >
            Login
          </button>
        </div>
      </form>
    </div>
    <div
      class="flex items-baseline justify-end w-screen h-20 bg-qgo-secondary-bg"
    >
      <img
        src="@/assets/img/LoginLogo.png"
        alt=""
        class="float-right w-16 h-16 mt-1 mr-5"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    methods: {
        reset() {
            
        }
    }
});
</script>