<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div
    class="flex flex-col items-center justify-center h-screen bg-qgo-primary-bg"
  >
    <div class="flex flex-col items-center justify-center w-2/3 h-screen mt-5">
      <div class="flex flex-row items-center justify-center w-full pt-5">
        <div class="border-r-2">
          <img
            src="@/assets/img/Q&GO_Vert_GreyWhite.png"
            alt=""
            class="object-scale-down w-52"
          />
        </div>
        <div class="px-2 text-3xl text-white">Vendor</div>
      </div>
      <form autocomplete="off">
        <div class="pt-6 mx-4 w-72">
          <div class="flex flex-col justify-center w-full">
            <input
              name="username"
              @blur="onBlur('email')"
              @input="emailVerification"
              v-model="input.email"
              type="email"
              class="
                mt-0
                block
                w-full
                px-0.5
                border-0 border-b
                text-white
                focus:ring-0 focus:border-qgo-secondary-bg
                bg-qgo-primary-bg
              "
              :class="
                !validStatus.email
                  ? 'placeholder-red-600  border-red-600'
                  : 'placeholder-white border-gray-100'
              "
              placeholder="Email or Phone number"
              v-on:keyup.enter="login"
            />
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.email"
            >
              {{ errors.email }}
            </p>
            <input
              name="password"
              v-model="input.password"
              @blur="onBlur('password')"
              @input="passwordVerification"
              type="password"
              class="
                mt-2
                block
                w-full
                px-0.5
                border-0 border-b
                text-white
                focus:ring-0 focus:border-qgo-secondary-bg
                bg-qgo-primary-bg
              "
              :class="
                !validStatus.password
                  ? 'placeholder-red-600  border-red-600'
                  : 'placeholder-white border-gray-100'
              "
              placeholder="Password"
              v-on:keyup.enter="login"
            />
            <p
              class="text-xs italic text-left text-red-500 errorMsg"
              v-if="!validStatus.password"
            >
              {{ errors.password }}
            </p>
          </div>
        </div>
        <div class="justify-center mx-4 w-72">
          <button
            id="loginButton"
            type="button"
            @click="login"
            class="
              w-full
              p-2
              my-1
              mt-1
              text-black
              bg-white
              rounded-md
              focus:outline-none
              mt-8
            "
          >
            Login
          </button>
          <button
            type="button"
            id="signupButton"
            class="
              w-full
              p-2
              my-1
              text-white
              rounded-md
              bg-qgo-secondary-bg
              focus:outline-none
            "
            @click="$router.push({ name: 'VendorSignup' })"
          >
            Become a Q&Go Vendor
          </button>
        </div>
        <div class="justify-center mx-4 w-72 text-white">
          <button
            type="button"
            @click="$router.push({ name: 'forgotPassword' })"
          >
            Forgot password
          </button>
        </div>
      </form>
    </div>
    <div
      class="flex items-baseline justify-end w-screen h-20 bg-qgo-secondary-bg"
    >
      <img
        src="@/assets/img/LoginLogo.png"
        alt=""
        class="float-right w-16 h-16 mt-1 mr-5"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  setup() {
    return {};
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      input: {
        email: "",
        password: "",
      },
      errors: {
        email: "",
        password: "",
      },
      validStatus: {
        email: true,
        password: true,
      },
      blurStatus: {
        email: "",
        password: "",
      },
    };
  },
  watch: {
    //for validating one input at a time
    username(value) {
      this.validateUsername(value);
    },
    password(value) {
      this.validatePassword(value);
    },
    //for validating both input at the same time
    // formData: {
    //   deep: true,
    //   handler(value) {
    //     console.log(value);
    //     this.validateUsername(value.username);
    //     this.validatePassword(value.password);
    //   }
    // }
  },
  methods: {
    emailVerification() {
      if (this.blurStatus.email) {
        if (this.input.email) {
          const rgex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(this.input.email)) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else if (rgex.test(this.input.email) && (this.input.email.length > 9 && this.input.email.length < 15)) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else {
            this.errors.email = "Please enter a valid email or phone number";
            this.validStatus.email = false;
          }
        } else {
          this.errors.email = "Email or Phone number is required";
          this.validStatus.email = false;
        }
      } else {
        this.errors.email = "";
        this.validStatus.email = true;
      }
    },
    passwordVerification() {
      if (this.blurStatus.password) {
        if (this.input.password) {
          this.errors.password = "";
          this.validStatus.password = true;
        } else {
          this.errors.password = "Password is required";
          this.validStatus.password = false;
        }
      } else {
        this.errors.password = "";
        this.validStatus.password = true;
      }
    },
    onBlur(field) {
      switch (field) {
        case "email":
          this.blurStatus.email = true;
          this.emailVerification();
          break;
        case "password":
          this.blurStatus.password = true;
          this.passwordVerification();
        default:
          break;
      }
    },
    setLoginData(val, e) {
      let payload = {
        input: val,
        value: e,
      };
      this.$store.commit("auth/login/setFormData", payload);
    },
    login() {
      console.log("login");
      this.onBlur("password");
      this.onBlur("email");
      if (this.validStatus.email && this.validStatus.password) {
        this.isLoading = true;
        let req = {
          email: this.input.email,
          password: this.input.password,
        };
        this.$http
          .post("auth/login", req)
          .then((response) => {
            if (response.data.user.role === "vendor") {
              this.isLoading = false;
              let data = {
                id: response.data.user.id,
                email: response.data.user.email,
                isLoggedIn: true,
                access_token: response.data.access_token,
                role: 2,
                name: response.data.user.name,
                logo: response.data.logo,
                isSocialLogin: false,
                socialType: "",
              };
              if (!response.data.user.last_login) {
                localStorage.setItem(
                  "last_login",
                  response.data.user.last_login
                );
              }
              this.$store.dispatch("auth/setLoginSuccess", data);
              this.$store.commit("profile/setVendorLogo", response.data.logo);
              this.$store.commit(
                "profile/setvendorName",
                response.data.user.name
              );
              this.setLoginData("username", "");
              this.setLoginData("password", "");
              this.$router.push({ name: "VendorProfileQ" });
            } else {
              this.$swal.fire({
                icon: "error",
                title:
                  "Sorry, You are not a vendor! Please sign up as a Vendor if you want to proceed.",
                timer: 8000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.errorMessage = error.message;
            let err = "";
            console.error("There was an error!", error);
            if (
              error.response.data.message === "Phone number is not verified"
            ) {
              sessionStorage.setItem("OTP_UserID", error.response.data.user_id);
              this.$router.push({ name: "OTPVerification" });
              err = error.response.data.message;
            }
            if (error.response.data.message) {
              err = error.response.data.message;
            }
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: err,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
        // if (
        //   this.formData.username == "testuser1" &&
        //   this.formData.password == "testpass"
        // ) {
        //   this.errorMsg = "";
        //   let body = {
        //     id: 11,
        //     email: "test@user.com",
        //     isLoggedIn: true,
        //   };
        //   this.$store.dispatch("auth/setLoginSuccess", body);
        //   this.$router.push({ name: "VendorBilling" });
        // } else if (
        //   this.formData.username == "testuser2" &&
        //   this.formData.password == "testpass"
        // ) {
        //   let body = {
        //     id: 12,
        //     email: "test@user.com",
        //     isLoggedIn: true,
        //   };
        //   this.$store.dispatch("auth/setLoginSuccess", body);
        //   this.$router.push({ name: "VendorBilling" });
        // } else {
        //   this.$store.commit(
        //     "auth/login/setErrorMsg",
        //     "Incorrect Username/Password"
        //   );
        //   this.$store.commit("auth/login/setValidatorUsername", true);
        //   this.$store.commit("auth/login/setValidatorPassword", true);
        // }
        // let body = {
        //   email: this.formData.username,
        //   password: this.formData.password
        // };
        // this.$http
        //   .post("user/signin", body)
        // .then(response => {
        //   let data = response.data.data;
        //   console.log(data);
        //   if (data.role === "vendor") {
        //     let payload = {
        //       id: data.id,
        //       email: data.email,
        //       access_token: data.access_token,
        //       isLoggedIn: true
        //     };
        //     this.$store.dispatch("auth/setLoginSuccess", payload);
        //     this.setLoginData("username", "");
        //     this.setLoginData("password", "");
        //     this.$router.push({ name: "VendorBilling" });
        //   } else {
        //     this.$swal.fire({
        //       icon: "error",
        //       text:
        //         "Sorry, You are not a vendor! Please sign up as a Vendor if you want to proceed.",
        //       timer: 8000
        //     });
        //   }
        // })
        // .catch(error => {
        //   this.errorMessage = error.message;
        //   console.error("There was an error!", error);
        //   this.$store.commit(
        //     "auth/login/setErrorMsg",
        //     "Incorrect Username/Password"
        //   );
        //   this.$store.commit("auth/login/setValidatorUsername", true);
        //   this.$store.commit("auth/login/setValidatorPassword", true);
        // });
      } else {
        this.$store.commit("auth/login/setValidatorUsername", true);
        this.$store.commit("auth/login/setValidatorPassword", true);
      }
    },
    validateUsername(value) {
      this.$store.commit(
        "auth/login/setValidatorUsername",
        this.validateEmpty(value)
      );
    },
    validatePassword(value) {
      this.$store.commit(
        "auth/login/setValidatorPassword",
        this.validateEmpty(value)
      );
    },
    validateEmpty(value) {
      return value ? false : true;
    },
  },
  computed: {
    formData() {
      return this.$store.getters["auth/login/formData"];
    },
    validators() {
      return this.$store.getters["auth/login/validators"];
    },
    errorMsg() {
      return this.$store.getters["auth/login/errorMsg"];
    },
    // for the watcher validators one at a time
    username() {
      return this.formData.username;
    },
    password() {
      return this.formData.password;
    },
  },
  components: {
    Loading,
  },
});
</script>
